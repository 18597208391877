/* eslint-disable @typescript-eslint/no-unused-vars */
import { Field, LinkField, RichText, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTheme } from 'lib/context/ThemeContext';
import { Form as FormikForm, Formik, FormikValues, FormikHelpers } from 'formik';
import { useCookies } from 'react-cookie';
import TextField from 'src/helpers/Form/TextField';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
import clsx from 'clsx';
import { Tokens, MeUser, LineItemProduct } from 'ordercloud-javascript-sdk';
import ComponentContext from 'lib/context/ComponentContext';
import { useRouter } from 'next/router';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { validateUser } from 'src/redux/ocAuth/login';
import { apiRequest } from 'src/utils/apiWrapper';
import CheckboxField from 'src/helpers/Form/CheckboxField';
import { MergeCart } from 'components/Checkout/helper/MergeCartHelper';
import { getUser } from 'src/redux/ocUser';
import { unwrapResult } from '@reduxjs/toolkit';
import { clearCurrentOrder } from 'src/redux/ocCurrentOrder';
import { getWishlist, setWishlistCollectionId, updateProductInWishlist } from 'src/redux/wishlist';
import { handleCTRewardsSignIn } from 'lib/crowd-twist/ct-sign-in';
import { getBVData } from 'components/BazarVoice/BazarVoiceToken';
import { setIsAnonymous } from 'src/redux/ocAuth';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import { trackUserLoginEvent } from '@sitecore-search/react';
import { removeSoftLoginDependencies } from 'src/utils/handleSoftLogin';
import { ErrorCodes, statusMessage } from 'src/helpers/Constants';
import Loader from 'components/Loader/Loader';
import { currentPath } from 'src/helpers/Constants';
import { triggerSubmitEvent } from 'src/helpers/formHelper';
import { useGraphQlDataContext } from 'lib/context/GraphQlDataContext';
import { loginFormTailwindVariant } from 'tailwindVariants/components/loginFormTailwindVariant';
import { loginAPI } from 'src/utils/nextApiConfig';
import sendStoreUpdate from 'src/utils/homeStoreHelper';

export type LoginFormProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      data: {
        data: {
          title: Field<string>;
          shortDescription: Field<string>;
          createLink: LinkField;
          formTitle: Field<string>;
          formDescription: Field<string>;
          ForgotPasswordLink: LinkField;
          formFields: {
            targetItems: Array<FormFieldsProps>;
          };
          forgotPasswordTitle?: Field<string>;
          forgotPasswordDisclaimer?: Field<string>;
          forgotPasswordSubmit?: Field<string>;
          forgotPasswordCancel?: Field<string>;
          afterSubmit: Field<string>;
          submitButtonText: Field<string>;
          ForgotPasswordText: Field<string>;
          successRedirectUrl?: { jsonValue: LinkField };
          failureMessage?: Field<string>;
          consentMessage?: Field<string>;
          migratedUserMessage?: Field<string>;
        };
      };
    };
  };
interface FormValuesTypes {
  Email: string;
}

const LoginForm = ({ fields, params }: LoginFormProps): JSX.Element => {
  const { themeName, themeNameUpper } = useTheme();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const [finishSubmit, setFinishSubmit] = useState(false);
  const [shouldRedirectHome, setShouldRedirectHome] = useState(true);

  const isOcAuth = useOcSelector((state) => state?.ocAuth);

  // Managing Auth users if attempt to redirect to login page.
  useEffect(() => {
    if (
      !isOcAuth.isAnonymous &&
      !isOcAuth.loading &&
      router?.asPath?.toLowerCase() === currentPath?.isLogin &&
      shouldRedirectHome
    ) {
      router.push(currentPath?.isHome);
    }
  }, [isOcAuth.isAnonymous, isOcAuth.loading]);

  // const { clearPriceCache } = useProductPrice();
  const dispatch = useOcDispatch();
  const {
    loginFormWrapper,
    form,
    fieldWrap,
    position,
    ctaLink,
    button,
    consentMsg,
    userFields,
    loaderWrapper,
    formTitle,
    buttonWrapper,
    migratedUserMessage,
  } = loginFormTailwindVariant({
    site: themeName,
    size: {
      initial: 'mobile',
      md: 'desktop',
    },
  });
  const [cookies, setCookie] = useCookies(['ct-auth', 'ordercloud.access-token']);
  const router = useRouter();
  const initialValues = {
    Email: '',
    Password: '',
    RememberMe: false,
  };
  const transFormFields = transformData(fields?.data?.data?.formFields);
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const getUSerWishlist = async (userObj: MeUser) => {
    await dispatch(setWishlistCollectionId(`Wishlist-${userObj?.ID}`));
    await dispatch(getWishlist(`Wishlist-${userObj?.ID}`));
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const myStoreData = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  const isLoginEnable = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.enableLogin?.jsonValue?.value
  );
  const hasMigratedUserMsg = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.migratedUserMessage
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const openForgotPassword = (event: any) => {
    if (event?.target?.tagName === 'STRONG') {
      setcomponentContextData((prevData) => ({
        ...prevData,
        ToggleForgotPasswordForm: true,
      }));
    }
  };

  const addProductInWishlist = async (
    productId: string,
    product: ProductSearchResultModelWithVariants | LineItemProduct
  ) => {
    await dispatch(
      updateProductInWishlist({
        productId: productId,
        product: product,
        storeId: myStoreData?.storeId,
        addOnly: true,
        clickFrom: componentContextData?.clickFrom,
      })
    );
  };
  const handleModalOpen = () => {
    setcomponentContextData({
      ...componentContextData,
      ToggleLoginOverlayForm: false,
      ToggleForgotPasswordForm: true,
    });
  };
  const { configuration } = useGraphQlDataContext();

  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  const lineItems = useOcSelector((state) => state?.ocCurrentOrder?.lineItems);
  const anonCartId = cart?.ID;
  const formRef = useRef<HTMLFormElement>(null);
  const anonProducts: string[] = lineItems?.map(function (val) {
    return val.ProductID;
  }) ?? [''];

  //return empty fregment if there is no data
  if (fields === undefined || fields === null) return <></>;
  const forLogin = async (values: FormikValues, _routeURL: string | undefined) => {
    setShouldRedirectHome(false);
    const token = cookies['ordercloud.access-token']
      ? cookies['ordercloud.access-token']
      : Tokens.GetAccessToken();

    if (values && values?.Email && values?.Password) {
      // MIDDLEWARE CALL: to call via a helper function
      // will need to send the below payload to an API endpoint here which will handle the flow.
      const loginUserUrl = loginAPI?.login;
      //const clientId = process.env.NEXT_PUBLIC_PSP_OC_CLIENT_ID;
      const headersData = {
        Authorization: token,
        site: themeNameUpper, // for now, only PSP would work, WNW integration is left from OC Marketplace
        // PSP-3043: Migrated User: Redirect to Reset Password to modify oldPassword
        requesturl: window.location.origin,
      };

      // Page redirect to Return Url
      const returnUrl = router?.query?.ReturnUrl || router?.query?.returnurl;

      const postData = {
        username: values?.Email,
        password: values?.Password,
        ...(componentContextData.productIdToAddInWishlistAfterLogin && {
          returnUrl:
            'ReturnUrl=' +
            router.asPath +
            '&favoritesku=' +
            componentContextData.productIdToAddInWishlistAfterLogin,
        }), // For login overlay cases
        ...(returnUrl && { returnUrl: `ReturnUrl=${returnUrl}` }), // Add returnUrl if it exists
      };

      const options = { method: 'POST', headers: headersData, data: postData };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await apiRequest(loginUserUrl, options);
      const credentials = {
        username: values?.Email,
        password: values?.Password,
      };
      if (
        response &&
        !response.Errors &&
        !(response?.Location && response?.Status == ErrorCodes?.Success)
      ) {
        triggerSubmitEvent(formRef, 'login', {
          userDetails: values?.Email,
        });
        await dispatch(setIsAnonymous(false));
        sendStoreUpdate(myStoreData?.storeId);
      }
      if (response && response.Errors) {
        setShowError(true);
        if (response.Errors && response.Errors[0]['ErrorCode'] == ErrorCodes?.LockedUser) {
          if (response?.ismobile) {
            router.push(
              configuration?.AccountUnlockPage +
                '?un=' +
                encodeURIComponent(values?.Email) +
                '&ismobile=true'
            );
          } else {
            router.push(
              configuration?.AccountUnlockPage + '?un=' + encodeURIComponent(values?.Email)
            );
          }
        }
      } else if (response && response?.Location && response?.Status == ErrorCodes?.Success) {
        if (componentContextData && componentContextData?.ToggleLoginOverlayForm) {
          const productId = componentContextData.productIdToAddInWishlistAfterLogin;
          const clickFrom = componentContextData.clickFrom;
          await setcomponentContextData({
            ...componentContextData,
            ToggleLoginOverlayForm: false,
          });
          if (productId) {
            const url = new URL(response?.Location, window.location.origin);
            url.searchParams.set('favoritesku', productId);
            url.searchParams.set('clickFrom', clickFrom);
            router.push(url.toString());
          }
        } else {
          const { query } = router;
          // PSP-3043: Migrated User: Redirect to Reset Password to modify oldPassword
          if (query.favoritesku) {
            const url = new URL(response?.Location, window.location.origin);
            url.searchParams.set('favoritesku', query.favoritesku?.toString());
            router.push(url.toString());
          } else {
            router.push(response?.Location);
          }
        }
      } else if (response && response?.access_token) {
        // 1. Get user details and check for verified and isActive.
        Tokens.SetAccessToken(response.access_token);
        removeSoftLoginDependencies();

        /**
         * if checked RememberMe, only than keep RefreshTokens else delete them.
         */
        if (response?.refresh_token && values?.RememberMe == true) {
          Tokens.SetRefreshToken(response.refresh_token);
          localStorage.setItem('isKeepMeLoggedIn', 'true');
        } else {
          // PSP-3871: If 'KeepMeLoggedIn' not checked, remove refresh tokens irrespective of SoftLogin check.
          Tokens.RemoveRefreshToken();
          if (localStorage.getItem('isKeepMeLoggedIn')) {
            localStorage.removeItem('isKeepMeLoggedIn');
          }
        }

        // Sending user login event to discover for tracking
        trackUserLoginEvent({
          email: values?.Email,
        });

        // clearPriceCache();
        setShowError(false);
        // This is not needed as we're fetching the latest user from the 'unwrapResult(result)' given below:
        // const userObj = await Me.Get();

        if (anonCartId) {
          await MergeCart(dispatch, anonCartId, anonProducts, token);
        }
        const result = await dispatch(getUser());
        dispatch(clearCurrentOrder());

        const user = unwrapResult(result);

        validateUser(user, credentials, response);
        if (user?.ID?.toLowerCase() !== 'defaultbuyer') {
          await getUSerWishlist(user);
        }
        const urlQuery = router?.query;
        if (urlQuery?.bveventid) {
          const data = await getBVData(
            {
              bvEventId: urlQuery?.bveventid,
            },
            themeName,
            response.access_token
          );
          if (data?.Url) {
            return router.push(data.Url);
          }
        }

        if (
          componentContextData &&
          componentContextData?.ToggleLoginOverlayForm &&
          componentContextData?.productIdToAddInWishlistAfterLogin
        ) {
          await addProductInWishlist(
            componentContextData?.productIdToAddInWishlistAfterLogin,
            componentContextData?.productToAddInWishlistAfterLogin
          );
          await setcomponentContextData({
            ...componentContextData,
            ToggleLoginOverlayForm: false,
          });
        } else if (componentContextData && componentContextData?.ToggleLoginOverlayForm) {
          await setcomponentContextData({
            ...componentContextData,
            ToggleLoginOverlayForm: false,
          });
        }

        if (response.firstlogin && user?.xp?.CartUser == true) {
          /**
           * PSP-1989: Return users created from Checkout to '/checkout' page upon first login
           */
          router.push(currentPath?.isCheckout || '/checkout');
        }
        // First Login
        else if (response.firstlogin && returnUrl !== '/checkout') {
          // todo: to add if user is in checkout flow, don't redirect (check with team)
          // router.push(configurationDetails?.TermsRewardPage || '/accountmanagement/my-rewards');

          // the router.push didn't work, the isAnonymous is getting updated after refresh
          window.location.href = configuration?.TermsRewardPage || '/accountmanagement/my-rewards';
        }
        // With Return URL
        else if (returnUrl) {
          // Previous changes
          if (router?.query?.favoritesku) {
            await addProductInWishlist(
              router?.query?.favoritesku?.toString(),
              componentContextData?.productToAddInWishlistAfterLogin
            );
          }

          // Checkout
          if (returnUrl === '/checkout') {
            /**
             * If we have a return URL, we need to be pushing the user to that particular URL.
             */
            router.push(returnUrl);
            // TODO: to confirm with team, and remove below snippet later:
            // if (routeURL) {
            //   router.push(routeURL);
            // } else {
            //   router.push('/');
            // }
          }

          // My Rewards
          else if (returnUrl?.includes(currentPath?.isReward)) {
            if (user && user?.xp?.LoyaltyAccepted === true) {
              // User accepted the Loyalty Terms and Conditions (sign into CT)
              if (user?.xp?.UnityId) {
                const ctSignInRedirectUrl = await handleCTRewardsSignIn();
                if (ctSignInRedirectUrl) {
                  setCookie('ct-auth', true);
                  // router.push(ctSignInRedirectUrl);
                  window.location.href = ctSignInRedirectUrl.toString();
                } else {
                  window.location.href = returnUrl.toString();
                }
              } else {
                window.location.href = returnUrl.toString();
              }
            }
            // User didn't accept Loyalty Terms and Conditions (do not sign into CT)
            else {
              // the router.push didn't work, the isAnonymous is getting updated after refresh
              // router.push(returnUrl.toString());
              window.location.href = returnUrl.toString();
            }
            //!not sure but if need for loyaltyAcceptance check then we can use this
            // else if (!user?.xp?.LoyaltyAccepted) {
            // router.push(currentPath?.isCheckout);
          } else {
            router.push((returnUrl as string) || '/');
          }
        }
        // Redirect to the homepage if no conditions meet
        else {
          // router.push('/');
          if (!componentContextData?.ToggleLoginOverlayForm) {
            router.push('/');
          }
        }
      }
      return setFinishSubmit(false);
    }
    return null;
  };
  // fetching forgotPasswordText:
  const forgotPasswordText =
    fields.data?.data?.formFields.targetItems.find((x) => x.id?.value === 'ForgotPassword')?.label
      ?.value || 'Forgot Passord';

  return (
    <>
      <div className={loginFormWrapper({ className: params?.Styles ?? '' })}>
        {/* 1sst Column */}
        {/* LOGIN FORM< */}
        <Formik
          initialValues={initialValues}
          onSubmit={async (
            values: FormValuesTypes,
            { setSubmitting }: FormikHelpers<FormValuesTypes>
          ) => {
            setFinishSubmit(true);
            await forLogin(values, fields.data?.data?.successRedirectUrl?.jsonValue?.value?.href);
            setSubmitting(false);
          }}
        >
          <FormikForm className={form()} ref={formRef}>
            {(fields?.data?.data?.title?.value || fields?.data?.data?.shortDescription?.value) && (
              <div className={'formTitleBlock()'}>
                <p className={'formTitle()'}>{fields?.data?.data?.title?.value}</p>
                <p className={'formDescription()'}>{fields?.data?.data?.shortDescription?.value}</p>
              </div>
            )}
            <div className={userFields()}>
              {showError && (
                <div className="text-system-red">{fields?.data?.data?.failureMessage?.value}</div>
              )}
              {/* General Message to display for migration */}
              {hasMigratedUserMsg?.value && (
                <RichText
                  tag="p"
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onClick={(event: any) => {
                    openForgotPassword(event);
                  }}
                  className={migratedUserMessage()}
                  field={hasMigratedUserMsg}
                />
              )}
              <p className={formTitle()}>
                {fields?.data?.data?.title?.value || 'Log in or create an account below.'}
              </p>

              <div className={fieldWrap()}>
                <TextField {...transFormFields.Email} />
              </div>
              <div className={clsx(fieldWrap(), position())}>
                <TextField
                  {...transFormFields.Password}
                  fieldtype={showPassword ? 'text' : 'password'}
                  togglePasswordVisibility={togglePasswordVisibility}
                  showPassword={showPassword}
                />
              </div>
            </div>
            <a aria-label="modal open" className={ctaLink()} onClick={handleModalOpen}>
              {forgotPasswordText}
            </a>
            <div className="flex flex-col gap-y-[12px]">
              {/* TODO: Find some condition to bifurcate between Login and LoginOverlay  */}
              {/* {!isAnonymous && ( */}
              <div className={fieldWrap()}>
                {<CheckboxField {...transFormFields.RememberMe} singleCheckbox={true} />}
              </div>
              {/* )} */}
              <p className={consentMsg()}>{fields?.data?.data?.consentMessage?.value}</p>
            </div>
            <div className={buttonWrapper()}>
              {!finishSubmit && isAnonymous && (
                <button
                  type="submit"
                  className={button({
                    outlineCta: false,
                    isDisabled: !(isLoginEnable === 'true'),
                  })}
                  disabled={!(isLoginEnable === 'true')}
                >
                  {fields?.data?.data?.submitButtonText?.value}
                </button>
              )}
              {finishSubmit && (
                <div className={loaderWrapper()}>
                  <Loader />
                  <p>{statusMessage?.loginInProgress}</p>
                </div>
              )}
            </div>
          </FormikForm>
        </Formik>
      </div>
    </>
  );
};
export default withDatasourceCheck()<LoginFormProps>(LoginForm);
