import React, { createContext, useContext, useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { FunctionComponent, useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { initializeAuth } from './ocAuth';
import logout from './ocAuth/logout';
import { setConfig } from './ocConfig';
import { removeLineItem, retrieveOrder } from './ocCurrentOrder';
import ocStore, { useOcDispatch, useOcSelector } from './ocStore';
import { getUser } from './ocUser';
import authAnonymous from './ocAuth/authAnonymous';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { setGlobalStoreSettings, setStore } from './storeDetailsSlice';
import { getGlobalStoreSettings } from 'src/helpers/StoreHelper';
import { getWishlist, setWishlistCollectionId } from './wishlist';
import { setGender, setSpecies } from './myPetsSlice';
import { setPetsGender, setPetsSpecies } from 'src/helpers/MyPetsHelper';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { fetchOtherSetting } from './otherSettingSlice';
import { useTheme } from 'lib/context/ThemeContext';
import { StoreObjectData } from 'src/pages/api/store/get-store-details';
import { useSiteName } from 'src/hooks/useSiteName';
import { getOcConfig } from 'src/utils/ocConfig';
import useOcCart from 'src/hooks/useOcCart';

interface OcProviderProps extends React.PropsWithChildren {
  storeData?: StoreObjectData | null;
}

type OcGlobalDataProps = React.PropsWithChildren;

export function useOcIsInitalized() {
  const ocInitPromise = useOcInitialize();

  const [ocInitalized, setOcInitalized] = useState(false);

  useEffect(() => {
    async function init() {
      await ocInitPromise;
      setOcInitalized(true);
    }
    init();
  }, [ocInitPromise]);

  return ocInitalized;
}

// Context to ensure only 1 instance of initializer is created
const OcInitializerContext = createContext<Promise<unknown>>(Promise.resolve());

const OcInitializer: FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const siteName = useSiteName();
  const config = useMemo(() => getOcConfig(siteName), [siteName]);

  const dispatch = useOcDispatch();

  // Using separate selectors to avoid issue with creating a new object in selector
  // which causes performance issues.  https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization
  const ocConfig = useOcSelector((s) => s.ocConfig);
  const ocAuth = useOcSelector((s) => s.ocAuth);
  const ocUser = useOcSelector((s) => s.ocUser);
  const ocCurrentOrder = useOcSelector((s) => s.ocCurrentOrder);
  const { getTipLineItem } = useOcCart();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);

  // Keep a reference to the promise resolve
  const ocInitPromiseResolveRef = useRef<(value?: unknown) => void>();
  // useMemo to ensure the same promise is returned every time.
  const ocInitPromise = useMemo(
    () =>
      new Promise((resolve) => {
        // Keep ref to resolve so we can call it later.
        // The useMemo should be called synchronously so it should be
        // available before the useEffect below
        ocInitPromiseResolveRef.current = resolve;
      }),
    []
  );

  // Track whether we're currently loading order
  const retrieveOrderInProgressRef = useRef(false);

  useEffect(() => {
    const removeTipLineItem = async () => {
      if (myStoreData?.disableTipping) {
        const tipItemId = getTipLineItem()?.ID;
        const isContainTipLineItem = ocCurrentOrder?.lineItems?.filter(
          (item) => item?.ID === tipItemId
        );
        tipItemId && isContainTipLineItem && (await dispatch(removeLineItem(tipItemId)));
      }
    };
    removeTipLineItem();
  }, [myStoreData?.disableTipping, ocCurrentOrder?.lineItems]);

  useEffect(() => {
    const fetchOcData = async () => {
      if (!ocConfig?.value || !isEqual(ocConfig?.value, config)) {
        await dispatch(setConfig(config));
      } else if (!ocAuth?.initialized) {
        await dispatch(initializeAuth());
      } else if (!ocAuth?.isAuthenticated && ocUser.user) {
        await dispatch(logout());
      } else if (!ocAuth?.isAuthenticated) {
        await dispatch(authAnonymous());
      } else if (ocAuth?.isAuthenticated) {
        if (!ocUser.user && !ocUser.loading) {
          await dispatch(getUser());
        }
        // !Important Note: MystoreData?storeId is not available then we are not calling the retrieveOrder.
        // if (myStoreData?.storeId && !ocCurrentOrder.initialized) {
        if (
          !retrieveOrderInProgressRef.current &&
          myStoreData?.storeId &&
          ocCurrentOrder.initialized == null
        ) {
          retrieveOrderInProgressRef.current = true;
          await dispatch(retrieveOrder());
          retrieveOrderInProgressRef.current = false;
        }
      }
      // Resolve the promise after init is complete
      ocInitPromiseResolveRef.current && ocInitPromiseResolveRef.current();
    };

    fetchOcData();
  }, [dispatch, config, ocConfig, ocAuth, ocUser, ocCurrentOrder, myStoreData?.storeId]);

  return (
    <OcInitializerContext.Provider value={ocInitPromise}>{children}</OcInitializerContext.Provider>
  );
};

export function useOcInitialize() {
  return useContext(OcInitializerContext);
}

// For setting and managing global data of project.
const OcGlobalData: FunctionComponent<OcGlobalDataProps> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const wishlist = useOcSelector((s) => s.wishlist);
  const ocUser = useOcSelector((s) => s.ocUser);
  const isAnonymous = useOcSelector((s) => s.ocAuth?.isAnonymous);
  const { themeNameUpper } = useTheme();
  const { sitecoreContext } = useSitecoreContext();
  const siteName = sitecoreContext?.site?.name ?? 'unknown';
  const language = 'en';

  /**
   * Wishlist SDK call based on user wishlist
   */
  useEffect(() => {
    if (
      !isAnonymous &&
      !wishlist?.wishlistCollectionId &&
      ocUser?.user?.ID &&
      ocUser?.user?.ID?.toLowerCase() !== 'defaultbuyer'
    ) {
      dispatch(setWishlistCollectionId(`Wishlist-${ocUser?.user?.ID}`));
    } else if (
      wishlist?.wishlistCollectionId &&
      !isAnonymous && // Added condition to avoid wishlist call at time of logout and for guest user.
      ocUser?.user?.ID
    ) {
      dispatch(getWishlist(`Wishlist-${ocUser?.user?.ID}`));
    }
  }, [ocUser?.user?.ID, isAnonymous, wishlist?.wishlistCollectionId]);

  /**
   * Get global settingsm, store settings, lookups
   */
  useEffect(() => {
    const getStoreSettings = async () => {
      dispatch(setGlobalStoreSettings(await getGlobalStoreSettings()));
    };
    getStoreSettings();
  }, []);

  /**
   * Fetching Pets Gender Details
   */

  useEffect(() => {
    const getPetDetails = async () => {
      !isAnonymous && dispatch(setGender(await setPetsGender()));
      !isAnonymous && dispatch(setSpecies(await setPetsSpecies()));
      dispatch(fetchOtherSetting({ language: language, siteName: siteName }));
    };
    getPetDetails();
  }, [siteName, themeNameUpper, isAnonymous]);

  return <>{children}</>;
};

const OcProvider: FunctionComponent<OcProviderProps> = ({ children, storeData }) => {
  const { sitecoreContext } = useSitecoreContext();

  const storeRef = useRef<typeof ocStore | null>(null);
  if (!storeRef.current) {
    storeRef.current = ocStore;

    // Set the store right away
    storeRef.current.dispatch(setStore(sitecoreContext.store || storeData));
  }
  /**
   * Set initial store
   */
  useEffect(() => {
    if (sitecoreContext.store?.storeId) {
      // TODO change local storage to use cookie instead.  Leave for now until all code is updated.
      localStorage.setItem('storeId', sitecoreContext.store.storeId || storeData?.storeId || '');
    }
  }, [sitecoreContext.store?.storeId, storeData?.storeId]);
  return (
    <>
      <Provider store={storeRef.current}>
        <OcInitializer>
          <OcGlobalData>{children}</OcGlobalData>
        </OcInitializer>
      </Provider>
    </>
  );
};

export default OcProvider;
